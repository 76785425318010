import React from 'react';
import * as styles from './HiringJoinWorld.module.scss';
import image1 from '../../../images/hiring-i1.png';
import image2 from '../../../images/hiring-i2.png';
import image3 from '../../../images/hiring-i3.png';
import image4 from '../../../images/hiring-i4.png';
import image from "../../../images/office123.jpg";
const HiringJoinWorld = () => {
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.textContainer}>
                    <h3>Become a part of the MASTER WiZR team and engage in our innovative, collaborative culture that emphasizes adaptability and excellence. </h3>
                    <p>Our vision is to create a workspace that enables productivity, collaboration, and fun. Let's make MASTER WiZR into a reality, where you can unleash your full potential and achieve your best work.</p>
                </div>
                <div>
                    {/* <StaticImage src='office123.jpg' alt='Image'/> */}
                </div>
            </div>
                    <img alt="space" className={styles.space} src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1681173675/web%20images/getwizrhiring_1_1_amriv2.png"} />
        </div>
    );
};

export default HiringJoinWorld;
