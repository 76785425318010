import React from "react";
import * as styles from "./BottomHiring.module.scss";
import image from "../../../images/master-your-material.png";
function BottomHiring(){
    return(
        <div className={styles.container}>
            <div className={styles.content}>
                <h1><span>Embark on an Innovation Journey: </span> <br/>Create and Market Top-Notch Global Software Solutions to Empower, Energize and Engage.</h1>
                <p>Become a part of our Master team of designers, marketers, salespeople, and engineers by submitting an application or 'green-card' job inquiry. <br/><br/> We consider strong candidates even when we have no open positions. Contact us today to learn more.</p>
            </div>
            <img alt="hiring bottom" src={image} />
        </div>
    );
}
export default BottomHiring;