import React, {useEffect, useState} from 'react';
import * as styles from './HiringWays.module.scss';
import SwipeableViews from "react-swipeable-views";
import Pagination from "../Pagination";
import {values} from "../../../constants/hiringValues";

const HiringWays = () => {
    const [width, setWidth] = useState(0);
    const [index, setIndex] = useState(0);
    const handleChangeIndex = (index) => {
        setIndex(index);
    };
    useEffect( () => {
        setWidth(window.innerWidth);
    },[]);

    const Text = ({title, description}) => {
        return (
          <div className={styles.items}>
              <h3>{title}</h3>
              <p>{description}</p>
          </div>
        );
    }

    const mobileView = () => {
        return (
            <div>
                <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
                    {values.map((value)=>{
                        return <Text title={value.title} description={value.description} />
                    })}
                </SwipeableViews>
                <Pagination dots={values.length} index={index}/>
            </div>
        );
    }

    const desktopView = () => {
        return (
            <div className={styles.desktopContainer}>
                {values.map((value)=>{
                    return <Text title={value.title} description={value.description} />
                })}
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <h2>The Wizy way</h2>
                <hr/>
                {width && width < 1024 ? mobileView() : desktopView()}
            </div>
        </div>
    );

};

export default HiringWays;
