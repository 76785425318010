import React, { useEffect } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import HeroBanner from "../components/atoms/HeroBanner";
import HiringJoinWorld from "../components/atoms/HiringJoinWorld";
import HiringWays from "../components/atoms/HiringWays";
import OpenPositions from "../components/ogranisms/OpenPositions";
import StyledButton from "../components/atoms/StyledButton";
import Head from "../components/ogranisms/head";
import { IoIosArrowForward } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";
import * as styles from "./styles.module.scss";
import WizyHiring from "../components/molecules/WizyHiring/WizyHiring";
import BottomHiring from "../components/ogranisms/bottomHiring/BottomHiring";
import { useLocation } from "@reach/router";
function Index() {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',  
    });
  }, [location]);
  
  return (
    <>
      <Head
        title="Live your best professional life"
        description="Join the WiZR world
        Your Favorite Work Space.Our ultimate goal is to make the MASTER WiZR reality a place you love to hang out in, be productive in collaborating, presenting, and doing meetings. We want your workspace to be as inspiring, efficient, and fun as possible."
        ogTitle="Hiring"
      />
      <main>
        <Header />
        <HeroBanner filename="hiring3d.png" extraClass="hiringPage">
          <h1 className={styles.hiringTitle} data-aos="zoom-in" data-aos-delay="200">
          Design the future, sell the vision, code the revolution. <br/> <span>Join the MASTER WiZR team today.</span> 
          </h1>
        </HeroBanner>
        <HiringJoinWorld />
        {/* <ImageSection /> */}
        <WizyHiring />
        <HiringWays />
        <BottomHiring />
        <Footer />
      </main>
    </>
  );
}

export default Index;
