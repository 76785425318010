import React, { useState, useEffect } from "react";
import * as styles from "./OpenPositions.module.scss";
import Position from "../../atoms/Position";
import { graphql, useStaticQuery } from "gatsby";
import StyledButton from "../../atoms/StyledButton";

const OpenPositions = () => {
  const { allWpJob } = useStaticQuery(
    graphql`
      query {
        allWpJob {
          edges {
            node {
              id
              title
              content
              jobDetails {
                deadline
                workType
              }
              slug
              categories {
                nodes {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    `
  );
  const [departments, setDepartments] = useState([
    "technology",
    "management",
    "marketing",
    "sales",
    "internship",
  ]);
  const [list, setList] = useState([]);
  const [index, setIndex] = useState(0);
  const [numberOfItems, setNumberOfItems] = useState(4);

  useEffect(() => {
    const filteredDepartments = departments.filter(department => {
      return allWpJob.edges.some(job => job.node.categories.nodes[0].slug === department);
    });
    setDepartments(filteredDepartments);
  
    let arrayList = [];
    allWpJob.edges.forEach(job => {
      if (filteredDepartments.includes(job.node.categories.nodes[0].slug)) {
        arrayList.push(job);
      }
    });
    setList(arrayList);
    setNumberOfItems(4);
  }, [allWpJob.edges]);
  

  return (
    <div id='positions' className={styles.container}>
      <div className={styles.upper}>
        <h2>All Open Positions</h2>
      </div>
      {departments.length > 1 && (
        <div className={styles.filters}>
          {departments.map((department, idx) => (
            <h3
              key={idx}
              onClick={() => {
                setDepartments([department]);
                setIndex(idx);
              }}
              className={`${index === idx && styles.active}`}
            >
              {department === 'management' ? 'Management' : department.charAt(0).toUpperCase() + department.slice(1)}
            </h3>
          ))}
        </div>
      )}
      <div className={styles.positionContainer}>
        {list &&
          list.map((job, index) => {
            if (index < numberOfItems) {
              return (
                <Position
                  key={job.node.id}
                  deadline={job.node.jobDetails.deadline}
                  workType={job.node.jobDetails.workType}
                  title={job.node.title}
                  link={job.node.slug}
                />
              );
            }
            return null;
          })}
      </div>
      {numberOfItems < list?.length && (
        <div
          onClick={() => {
            setNumberOfItems(numberOfItems + 4);
          }}
          className={styles.lower}
        >
          <StyledButton title="See More" icon extraClass="orangeGray" />
        </div>
      )}
    </div>
  );
};

export default OpenPositions;
