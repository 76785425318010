import React from "react";
import * as styles from "./WizyHiring.module.scss";
import topimage from "../../../images/wizyloft.jpg";

function WizyHiring(){
    return(
        <div className={styles.container}>
          <h1>Come play with us and Wizy</h1>
          <div className={styles.collage}>
              <img className={styles.topimage} alt="top wizy" src={topimage} />
              <div className={styles.bottom}>
                  <img
                  alt="hiring 1"
                   src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680226140/web%20images/Unreal_Loft_Wizy_1_xhxl56.jpg"}/>
                  <img
                  alt="hiring 2"
                   src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680226140/web%20images/Wizy_TV_3_dyshhv.jpg"}/>
                  <img
                  alt="hiring 3"
                   src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680226140/web%20images/Wizy_TV_2_caqzma.jpg"}/>
              </div>
          </div>
          <p className={styles.subtitle}><b>Say hello to Wizy, our lovable mascot who embodies the spirit of what we're about at MASTER WIZR.</b>
          <br/><br/>
          Wizy guides and inspire us to imagine the digital future that we want to create and be a part of. Together with Wizy, we're working to build the ultimate workspace, where creativity, innovation, and collaboration thrive.
          </p>
        </div>
    );
}
export default WizyHiring;